<template>
	<div class="detail_content">
		<div class="content_tit_wrap">
			<div class="shortline"></div>
			<div class="tit_line">
				<h2>멤버쉽 이용권</h2>
			</div>
		</div>
		<div class="pay_list_wrap">
			<div v-for="(item, index) in charge_list" :key="index" class="pay_list">
				<ul>
					<li>
						<input
							v-model="checkedItemPrice"
							@input="checkItem(item, index)"
							:value="item.price"
							type="radio"
							name="chargeItem"
							:id="`pay_check${index}`"
						/><label :for="`pay_check${index}`">
							<div class="ep_tit">
								멤버쉽 <span>{{ item.item_name }}</span
								>이용권
							</div></label
						>
						<!-- <div class="price_wrap"><span>30,000</span>{{ item.price }} ₩</div> -->
						<div class="price_wrap">{{ item.price.toLocaleString() }} ₩</div>
					</li>
				</ul>
			</div>
			<div class="total_price">
				<div class="ep_tit">
					멤버쉽 <span>{{ check_practice.item_name }}</span
					>이용권 선택
				</div>
				<div class="payment_wrap"><span>결제 금액</span> {{ checkedItemPrice }} ₩</div>
			</div>
		</div>
	</div>
	<div class="detail_content">
		<div class="content_tit_wrap">
			<div class="shortline"></div>
			<div class="tit_line">
				<h2>결제수단</h2>
			</div>
		</div>
		<div class="pay_box_wrap">
			<ul>
				<li
					@click="pay_ck(index)"
					:value="index"
					id="pay_list"
					v-for="(item, index) in extension_list"
					:key="index"
				>
					{{ item }}
				</li>
			</ul>
		</div>
		<div class="agreement_bg_wrap">
			<div class="agreement_check_box_wrap">
				<div class="agreement_check_box">
					<input v-model="first_terms" type="checkbox" class="g_check3" id="g_check6" /><label
						for="g_check6"
					></label>
					<label for="g_check6">[필수]서비스 이용약관 및 개인정보 수집 및 이용에 동의합니다.</label>
				</div>
				<div class="agreement_check_box">
					<input v-model="second_terms" type="checkbox" class="g_check3" id="g_check7" /><label
						for="g_check7"
					></label>
					<label for="g_check7">[필수]서비스 이용약관 및 개인정보 수집 및 이용에 동의합니다.</label>
				</div>
			</div>
		</div>
		<div class="btn_wrap_line">
			<button @click="charge_completet()" type="button" class="pay_btn">결제하기</button>
		</div>
	</div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import ajax from '@/api/api-manager';
import jwt from '@/api/LocalStorage';
import axios from 'axios';
import { getLoginInfo, checkLogin } from '@/common-methods/checkAccount';

const extension_list = ref(['카카오페이', '네이버페이', '토스간편결제', '신용카드']);
const router = useRouter();
const charge_list = ref(null);
const check_price = ref('0');
const check_practice = ref('');
const list_index = ref(null);
const pay_value = ref(null);
const season_ticket_value = ref(null);
const first_terms = ref(null);
const second_terms = ref(null);
const loginState = ref(checkLogin());

// 나의 요금제 현황 조회
if (loginState.value) {
	ajax('charge/use_current_plan').then((res) => {
		if (res.data.data === null) {
			console.log('구매 이력 없음');
			return;
		} else {
			alert('이미 멤버쉽 회원입니다!');
			router.go(-1);
		}
	});
	ajax(`charge/charge_list`).then((res) => {
		charge_list.value = res.data.data;
		console.log(res.data.data);
	});

	ajax(`charge/season_ticket_value`).then((res) => {
		season_ticket_value.value = res.data.data;
	});
} else {
	alert('로그인 먼저 진행해주세요');
	router.replace('/login');
}

const pay_ck = (index) => {
	const pay_list = document.querySelectorAll('#pay_list');
	pay_list[index].classList = 'active';
	switch (index) {
		case 0:
			pay_list[1].classList = '';
			pay_list[2].classList = '';
			pay_list[3].classList = '';
			pay_value.value = 'kakaopay';
			break;
		case 1:
			pay_list[0].classList = '';
			pay_list[2].classList = '';
			pay_list[3].classList = '';
			pay_value.value = 'naverpay';
			break;
		case 2:
			pay_list[0].classList = '';
			pay_list[1].classList = '';
			pay_list[3].classList = '';
			pay_value.value = 'tosspay';
			break;
		case 3:
			pay_list[0].classList = '';
			pay_list[1].classList = '';
			pay_list[2].classList = '';
			pay_value.value = 'card';
			break;
	}
};

const checkedItemPrice = ref('0');
const checkItem = (item, index) => {
	check_practice.value = item;
	list_index.value = index;
};
// const price_select = (index) => {
// 	const checked_data = document.querySelectorAll('#pay_check');
// 	checked_data[index].checked = true;

// 	switch (index) {
// 		case 0:
// 			checked_data[1].checked = false;
// 			checked_data[2].checked = false;
// 			break;
// 		case 1:
// 			checked_data[0].checked = false;
// 			checked_data[2].checked = false;
// 			break;
// 		case 2:
// 			checked_data[0].checked = false;
// 			checked_data[1].checked = false;
// 			break;
// 	}
// 	checked_data[index].checked
// 		? (check_price.value = charge_list.value[index].price) && (check_practice.value = charge_list.value[index])
// 		: null;
// 	list_index.value = index;
// };

const charge_completet = () => {
	switch (true) {
		case pay_value.value !== null &&
			// check_price.value !== '0' &&
			checkedItemPrice.value !== '0' &&
			!season_ticket_value.value &&
			second_terms.value &&
			first_terms.value: {
			iamport_incis_recurring();
			break;
		}
		// case check_price.value === '0':
		// 	alert('품목을 설정해주세요!');
		// 	break;
		case checkedItemPrice.value === '0':
			alert('품목을 설정해주세요!');
			break;
		case pay_value.value === null:
			alert('결제하실 수단을 선택해주세요!');
			break;
		case !second_terms.value || !first_terms.value:
			alert('필수 이용약관에 모두 동의하셔야 합니다!');
			break;
		case season_ticket_value.value:
			alert('이미 구매하신 정기권이 있습니다! 만료 후 다시 구매해주세요!');
			break;
	}
};

const iamport_incis_recurring = () => {
	const token = jwt.getToken('id_token');
	const id = jwt.getToken('id');
	const IMP = window.IMP;
	//가맹점 식별코드
	let itemName = check_practice.value.item_name;
	// let itemPrice = check_price.value;
	let itemPrice = checkedItemPrice.value;
	console.log(itemName);
	let merchant_uid = 'merchant_' + new Date().getTime(); // 상점에서 관리하는 주문 번호
	let customer_uid = 'customer_' + new Date().getTime(); // 상점에서 관리하는 주문 번호
	IMP.init('imp71379418');
	IMP.request_pay(
		{
			pg: 'html5_inicis.INIpayTest',
			pay_method: pay_value.value,
			merchant_uid,
			// customer_uid,
			custom_data: {
				itemCode: charge_list.value[list_index.value].item_code,
				token: token,
				paymentType: pay_value.value,
			},
			name: itemName,
			amount: itemPrice,
			buyer_email: id,
			buyer_tel: '000-0000-0000',
			buyer_name: id,
			notice_url: 'https://www.toonflex.co.kr/sbapi/iamport-webhook',
			m_redirect_url: `${window.location.host}/payments/complete`, // 결제 실패,성공 시 모바일 버전에선 해당 URL로 이동함
			//     // '/completeIamport', // 예: https000://www.my-service.com/payments/complete
			//     `${this.$baseUrl}/charge/cash/completeIamport`,
		},
		(response) => {
			console.log(response);
			if (response.success) {
				alert('결제성공');
				// jwt.saveToken('membership', 'Y');
				router.replace('/mypage/membership');
				// alert(customer_uid);
				// customer_uid = 'customer_' + new Date().getTime(); // 상점에서 관리하는 주문 번호
			} else {
				alert('결제실패');
			}
		},
	);
};
</script>
<style scoped>
#pay_list {
	cursor: pointer;
}
</style>
